<template>
  <v-card
    >
    <v-card-title
      class="justify-center"
      style="height: 96px !important"
      >
      Reporte Producción Supervisores
    </v-card-title>
    <v-card-text>
      <div
        style="height: 230px"
        >
        <v-dialog
          ref="dialog"
          :return-value.sync="range[0]"
          width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="range[0]"
              label="Fecha desde"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              class="my-3"
              hide-details
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
          <v-date-picker
            v-model="range[0]"
            scrollable
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(range[0])"
              >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-dialog
          ref="dialog2"
          :return-value.sync="range[1]"
          width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="range[1]"
              label="Fecha hasta"
              prepend-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
          <v-date-picker
            v-model="range[1]"
            scrollable
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog2.save(range[1])"
              >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-card-text>

    <v-card-actions
      class="justify-end"
      >
      <v-btn
        depressed
        color="transparent"
        @click="goToReport('supervisors_production')"
        >
        <img
          src="@/assets/icons/tiantar-download-excel.png"
          height="36px"
          width="36px"
          ></img>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    range: []
  }),

  computed: {
    ...mapGetters(['currentUser', 'url', 'project'])
  },

  methods: {
    goToReport(report) {
      var url = process.env.VUE_APP_RAILS_URL
      var query = '?'

      if (this.range[0]) query += `from=${this.range[0]}&`
      if (this.range[1]) query += `to=${this.range[1]}&`

      window.open(`${url}/${report}_report/${this.$route.params.id}${query}uid=${this.currentUser.id}`)
    }
  }
}
</script>
