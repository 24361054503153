<template>
  <v-container
    fluid
    class="pa-0"
    >
    <div
      class="full-background"
      ></div>

    <div>
      <v-card
        flat
        color="transparent"
        class="text-h3 font-weight-bold d-flex align-center justify-center white--text"
        height="300"
        >
        Reportes
      </v-card>

      <v-card
        flat
        tile
        class="lower-card-opacity"
        >
        <v-card-text
          style="calc(100vh - 348px)"
          >
          <v-row
            align="stretch"
            class="flex-wrap"
            >
            <v-col
              style="width: 20%"
              >
              <v-card
                class="fill-height"
                >
                <v-card-title
                  class="justify-center"
                  style="height: 96px !important"
                  >
                  Reporte Rollout
                </v-card-title>
                <v-card-text
                  >
                  <div
                    style="height: 230px"
                    >
                    Exporta todas las tareas con status desde "Asignado" hasta "Documentación enviada". También trae las tareas canceladas.
                  </div>
                </v-card-text>

                <v-card-actions
                  class="justify-end mt-3"
                  >
                  <v-btn
                    depressed
                    color="transparent"
                    @click="goToReport(project.projectType == 'huawei' ? 'huawei_rollout' : 'rollout')"
                    >
                    <img
                      src="@/assets/icons/tiantar-download-excel.png"
                      height="36px"
                      width="36px"
                      ></img>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <template
              v-if="currentUser && (['shibui', 'management', 'finance'].includes(currentUser.currentRoleName))"
              >
              <v-col
                style="width: 20%"
                >
                <billing></billing>
              </v-col>

              <v-col
                style="width: 20%"
                >
                <supervisors></supervisors>
              </v-col>

              <v-col
                style="width: 20%"
                >
                <v-card
                  class="fill-height"
                  >
                  <v-card-title
                    class="justify-center"
                    style="height: 96px !important"
                    >
                    Reporte PO / <template v-if="project.projecType == 'huawei'">ESAR</template> <template v-else>IDOC</template>
                  </v-card-title>
                  <v-card-text
                    >
                    <div
                      style="height: 230px"
                      >
                      <template
                        v-if="project.projecType == 'huawei'"
                        >
                        Reporte de actividades con Doc. Enviada, pendiente de ESAR.
                      </template>

                      <template
                        v-else
                        >
                        Exporta todas las POs que aún no han sido enviadas a facturar.
                      </template>
                    </div>
                  </v-card-text>

                  <v-card-actions
                    class="justify-end mt-3"
                    >
                    <v-btn
                      depressed
                      color="transparent"
                      @click="goToReport(project.projectType == 'huawei' ? 'esar' : 'idoc')"
                      >
                      <img
                        src="@/assets/icons/tiantar-download-excel.png"
                        height="36px"
                        width="36px"
                        ></img>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col
                style="width: 20%"
                >
                <v-card
                  class="fill-height"
                  >
                  <v-card-title
                    class="justify-center"
                    style="height: 96px !important"
                    >
                    Reporte Actividad / PO
                  </v-card-title>
                  <v-card-text
                    >
                    <div
                      style="height: 230px"
                      >
                    </div>
                  </v-card-text>

                  <v-card-actions
                    class="justify-end mt-3"
                    >
                    <v-btn
                      depressed
                      color="transparent"
                      @click="goToReport('activity_po')"
                      >
                      <img
                        src="@/assets/icons/tiantar-download-excel.png"
                        height="36px"
                        width="36px"
                        ></img>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import Billing from '@/components/reports/Billing'
import Supervisors from '@/components/reports/Supervisors'
import { mapGetters } from 'vuex'

export default {
  methods: {
    goToReport(report) {
      window.open(`${this.url}/${report}_report/${this.$route.params.id}?uid=${this.currentUser.id}`)
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url', 'project'])
  },

  components: {
    Billing,
    Supervisors
  }
}
</script>
