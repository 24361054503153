<template>
  <v-container
    v-if="project && currentUser && (['shibui', 'management', 'finance'].includes(currentUser.currentRoleName))"
    fluid
    class="pa-0 fill-height"
    >
    <div
      class="full-background"
      ></div>

    <div
      class="fill-width"
      >
      <v-card
        flat
        color="transparent"
        class="text-h3 font-weight-bold d-flex align-center justify-center white--text"
        height="300"
        >
        Carga masiva
      </v-card>

      <v-card
        flat
        tile
        class="lower-card-opacity fill-width"
        >
        <v-card-text
          >
          <v-row>
            <v-col
              cols="12"
              md="2"
              >
              <billing></billing>
            </v-col>

            <v-col
              v-if="(['shibui', 'management', 'finance'].includes(currentUser.currentRoleName))"
              cols="12"
              md="2"
              >
              <po
                v-if="project.projectType == 'nokia'"
                ></po>

              <huawei-po
                v-else
                ></huawei-po>
            </v-col>

            <v-col
              v-if="(['shibui', 'management', 'finance'].includes(currentUser.currentRoleName))"
              cols="12"
              md="2"
              >
              <idoc
                v-if="project.projectType == 'nokia'"
                ></idoc>

              <esar
                v-else
                ></esar>
            </v-col>

            <v-col
              cols="12"
              md="2"
              >
              <payment-condition></payment-condition>
            </v-col>

            <v-col
              cols="12"
              md="2"
              v-if="project.projectType == 'huawei'"
              >
              <price-comparer></price-comparer>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
const Billing = () => import('@/components/importers/Billing')
const Po = () => import('@/components/importers/Po')
const HuaweiPo = () => import('@/components/importers/HuaweiPo')
const idoc = () => import('@/components/importers/Idoc')
const esar = () => import('@/components/importers/Esar')
const PaymentCondition = () => import('@/components/importers/PaymentCondition')
const PriceComparer = () => import('@/components/importers/PriceComparer')

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentUser', 'project'])
  },

  components: {
    Billing,
    Po,
    idoc,
    PaymentCondition,
    HuaweiPo,
    esar,
    PriceComparer
  },
}
</script>
